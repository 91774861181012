import React from 'react';
import pic from '../assets/img/svg/objects.svg'
import { LazyLoadImage } from "react-lazy-load-image-component"

const DecorElement = () => {
  return (
    <div className="flex jcfs aic my-5 m-block sign">
      <LazyLoadImage
        width={666}
        height={222}
        src={pic}
        alt="feature"
        className="mx-1"
      />
    </div>
  )
}

export default DecorElement
